<template>
	<main class="page-container" id="join-intro">
		<section class="mbrp-section">
			<div class="container">
				<header class="mbrp-header">
					<h2 class="title">통합 회원 가입하기</h2>
				</header>
				<div class="mbrp-body">
					<!-- steps -->
					<div class="steps-wrapper">
						<ol class="steps">
							<li class="steps-segment is-complete">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">1</span>
									</span>
									<div class="steps-content">
										<p class="heading">약관동의</p>
									</div>
								</div>
							</li>
							<li class="steps-segment is-complete">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">2</span>
									</span>
									<div class="steps-content">
										<p class="heading">기업회원 인증</p>
									</div>
								</div>
							</li>
							<li class="steps-segment is-complete">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">3</span>
									</span>
									<div class="steps-content">
										<p class="heading">회원정보 입력</p>
									</div>
								</div>
							</li>
							<li class="steps-segment is-active">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">4</span>
									</span>
									<div class="steps-content">
										<p class="heading">회원가입 완료</p>
									</div>
								</div>
							</li>
						</ol>
					</div>
					<!-- //steps -->

					<section class="mbrp-complete">
						<h3 class="mbrp-complete-header">통합기업 회원가입 완료</h3>

						<div class="mbrp-complete-content">
							<div class="success-checkmark">
								<div class="check-icon">
									<span class="icon-line line-tip"></span>
									<span class="icon-line line-long"></span>
									<div class="icon-circle"></div>
									<div class="icon-fix"></div>
								</div>
							</div>
							<strong class="mbrp-complete-content__title"> 환영합니다. {{userNm}} 사장님. </strong>
							<p class="mbrp-complete-content__desc">
								회원가입이 완료되었습니다. <br />
								가입하신 해당 계정으로 <strong class="color-orange">가치삽시다</strong>와 <strong class="color-orange">판판대로</strong>의 서비스를 모두 이용할 수 있습니다.
							</p>
						</div>
					</section>
					<div class="buttons text-center">
						<router-link to="/login" class="button-default is-large is-primary is-rounded">로그인</router-link>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	name: 'JoinIntegratedComplete',
	components: {},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
    ...mapGetters('join', ['userNm'])
	},
	watch: {},
	data: () => ({}),
	mounted() {},
	methods: {},
	destroyed() {},
};
</script>
